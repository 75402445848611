import React, { useState, useEffect } from 'react';


import {
    BrowserBarcodeReader,
    NotFoundException,
    ChecksumException,
    FormatException
} from "@zxing/library";
import { getProduct } from '../partials/foodApi.js/foodApi';
import FoodModal from '../partials/meal/FoodModal';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

const codeReader = new BrowserBarcodeReader();
console.log("ZXing code reader initialized");


function Camera() {
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [item, setItem] = useState("");
    const [code, setCode] = useState('')
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const setupDevices = (videoInputDevices) => {

        setSelectedDeviceId(videoInputDevices[1]?.deviceId ?? videoInputDevices[0]?.deviceId);

    }

    useEffect(() => {
        codeReader
            .getVideoInputDevices()
            .then(videoInputDevices => {
                setupDevices(videoInputDevices);
            })
            .catch(err => {
                console.error(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const decodeContinuously = (selectedDeviceId) => {
        codeReader.decodeFromInputVideoDeviceContinuously(
            selectedDeviceId,
            "video",
            async (result, err) => {
                if (result) {
                    codeReader.stopContinuousDecode()

                    setCode(result.text)
                    const resp = await getProduct(result.text)

                    setItem(resp)
                }

                if (err) {
                    if (err instanceof NotFoundException) {
                        // console.log("No QR code found.");
                    }

                    if (err instanceof ChecksumException) {
                        // console.log("A code was found, but it's read value was not valid.");
                    }

                    if (err instanceof FormatException) {
                        // console.log("A code was found, but it was in a invalid format.");
                    }
                }
            }
        );
    }

    useEffect(
        () => {
            decodeContinuously(selectedDeviceId);
            console.log(`Started decode from camera with id ${selectedDeviceId}`);
        },
        [selectedDeviceId]
    );

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <main className="bg-white">

                        {item ?
                            <FoodModal baseItem={item} force={true}>
                            </FoodModal>
                            : null}

                        {/* Content */}

                        <video className="w-full" id="video" />
                        {code}
                </main>
            </div>
        </div>
    );
}

export default Camera;