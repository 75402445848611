import React, { useContext, useMemo, useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import MealModal from '../partials/meal/MealModal';
import { UserContext } from '../context/user';

function Meal() {

    const [items, setItems] = useState([]);

    const { user } = useContext(UserContext)
    const [sidebarOpen, setSidebarOpen] = useState(false);


    const addItem = (item) => {
        setItems([...items, item])
    }

    const updateItem = (newItem) => {

        const newItems = items.map((item) => {
            if (item.name === newItem.name) {
                return newItem
            }
            return item
        })
        setItems(newItems)
    }

    const discardItem = (discredItem) => {

        const newItem = items.filter((item) => {
            return item.id !== discredItem.id
        })

        setItems(newItem)
    }



    const [stage, setStage] = useState('lunch')

    const factor = useMemo(() => user[stage] / 10, [stage, user])

    const doses = useMemo(() => {

        const sum = items.reduce((prev, item) => {

            const total = item.qty * item.sugar

            return prev + total
        }, 0)

        return sum * factor
    }, [items, factor])

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        <div className="sm:flex sm:justify-between sm:items-center xl:flex xl:justify-between xl:items-center mb-3">
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Meal</h1>
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-0.5 mb-3">
                            <div onClick={() => setStage('breakfast')} className={`sm:col-span-1 xl:col-span-1 ${stage === 'breakfast' ? 'bg-indigo' : 'bg-white'} shadow-lg rounded-sm border border-gray-200`}>
                                <div className="flex flex-col h-full text-center p-3">
                                    <div className="flex-grow mb-1">
                                        <div className="inline-flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-2">
                                            🥐
                                            </div>
                                        <h3 className="text-lg text-gray-800 font-semibold mb-1">Breakfast</h3>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => setStage('lunch')} className={`sm:col-span-1 xl:col-span-1 ${stage === 'lunch' ? 'bg-indigo' : 'bg-white'} shadow-lg rounded-sm border border-gray-200`}>
                                <div className="flex flex-col h-full text-center p-3">
                                    <div className="flex-grow mb-1">
                                        <div className="inline-flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-2">
                                            🍔
                                            </div>
                                        <h3 className="text-lg text-gray-800 font-semibold mb-1">Lunch</h3>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => setStage('dinner')} className={`sm:col-span-1 xl:col-span-1  ${stage === 'dinner' ? 'bg-indigo' : 'bg-white'}  shadow-lg rounded-sm border border-gray-200`}>
                                <div className="flex flex-col h-full text-center p-3">
                                    <div className="flex-grow mb-1">
                                        <div className="inline-flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-2">
                                            🍷
                                            </div>
                                        <h3 className="text-lg text-gray-800 font-semibold mb-1">Diner</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200">
                            <header className="px-5 py-4 border-b border-gray-100 flex justify-between">
                                <h2 className="font-semibold text-gray-800">Meal</h2>
                                <MealModal onSubmit={addItem} onDelete={discardItem}> Add </MealModal>

                            </header>
                            <div className="p-3">
                                <div>
                                    <ul className="my-1">
                                        {items.map(item => {

                                            return (
                                                <li className="flex px-2" key={item.name}>
                                                    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-red-500 my-2 mr-3 leading-9 text-center">
                                                        {item.icon ?? '🍴'}
                                                    </div>
                                                    <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
                                                        <div className="flex-grow flex justify-between">
                                                            <div className="self-center">
                                                                <a className="font-medium text-gray-800" href="#0">{item.name}</a> x{item.qty} <a className="font-medium text-gray-800 hover:text-gray-900" href="#0">{(item.sugar * item.qty).toFixed(1)}g</a></div>
                                                            <div className="flex-shrink-0 self-end ml-2">
                                                                <MealModal baseItem={item} onSubmit={updateItem} onDelete={discardItem}>
                                                                    <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400">
                                                                        <path d="M1 16h3c.3 0 .5-.1.7-.3l11-11c.4-.4.4-1 0-1.4l-3-3c-.4-.4-1-.4-1.4 0l-11 11c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1zm1-3.6l10-10L13.6 4l-10 10H2v-1.6z">
                                                                        </path>
                                                                    </svg>
                                                                </MealModal>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-gray-200">
                            <div className="flex flex-col h-full text-center p-3">
                                <div className="flex-grow mb-1">
                                    <div className="inline-flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-2">
                                        💉
                                    </div>
                                    <h3 className="text-lg text-gray-800 font-semibold mb-1">x{doses.toFixed(1)}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default Meal;