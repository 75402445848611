import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../../utils/Transition.js';
import { useInput, chill } from '../../utils/UseInput';
import { Picker } from 'emoji-mart'
import { FoodItemContext } from '../../context/db.js';

const unitMap = {
    'g': 'per g',
    'n': 'per unit',
    'ml': 'per ml'
}

function MealModal({ onSubmit, baseItem, children, onDelete }) {

    const [searchOpen, setSearchOpen] = useState(false);
    const [searchValue, searchChange, setSeachValue] = useInput('')
    const [sugar, sugarChange, setSugar] = useInput(0)
    const [qty, qtyChange, setQty] = useInput(1)
    const trigger = useRef(null);
    const searchContent = useRef(null);
    const searchInput = useRef(null);
    const [name, nameChange, setName] = useInput('')
    const [unit, unitChange, setUnit] = useInput('')
    const [icon, setIcon] = useState('🍴')
    const [picker, setPicker] = useState(false)

    const [item, setItem] = useState(baseItem ?? null)

    const [page, setPage] = useState(baseItem ? 'qty' : 'search')

    const { foodItems, addFoodItem } = useContext(FoodItemContext)

    const selectItem = useCallback((item) => {
        setPage('qty')
        setItem(item)
        setSugar(item.sugar)
        setQty(item.base)
    }, [setPage, setItem, setSugar, setQty])

    const newItem = useCallback(() => {
        setPage('new')
        setName(searchValue)

    }, [setPage, searchValue, setName])

    const selectEmoji = useCallback((emoji) => {
        setIcon(emoji.native)
        setPicker(false)
    }, [])

    const openModal = useCallback(() => {
        if (baseItem) {
            setPage('qty')
            setItem(baseItem)
            setSugar(baseItem.sugar)
            setQty(baseItem.qty)
        } else {
            setPage('search')
            setItem(null)
            setName('')
            setSugar(0)
            setUnit(null)
            setIcon('🍴')
            setSeachValue('')
        }

        setSearchOpen(!searchOpen);

    }, [setPage, setSearchOpen, setItem, searchOpen, baseItem, setQty, setSugar, setName, setSeachValue, setUnit])

    const onAdd = useCallback(() => {
        onSubmit({
            ...item,
            qty,

        })
        setSearchOpen(false)

    }, [item, setSearchOpen, onSubmit, qty])

    const onNew = useCallback(async () => {

        const newItem = {
            name,
            unit,
            icon,
            base: qty,
            sugar: sugar / qty
        }
        const { id } = await addFoodItem(newItem)

        onSubmit({
            ...newItem,
            id,
            qty,
        })

        setSearchOpen(false)
    }, [setSearchOpen, onSubmit, qty, name, unit, icon, sugar, addFoodItem])

    const onDiscard = useCallback(() => {
        onDelete(item)
    }, [onDelete, item])

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (picker) return
            if (!searchOpen || searchContent?.current?.contains?.(target) || trigger?.current?.contains?.(target)) return;
            setSearchOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!searchOpen || keyCode !== 27) return;
            setSearchOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                ref={trigger}
                aria-controls="search-modal"

                onClick={() => {
                    openModal()
                    setImmediate(() => searchInput?.current?.focus());
                }}
            >
                {children}
            </button>
            {/* Modal backdrop */}
            <Transition
                className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
                show={searchOpen}
                enter="transition ease-out duration-200"
                enterStart="opacity-0"
                enterEnd="opacity-100"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                aria-hidden="true"
            />
            {/* Modal dialog */}
            <Transition
                id="search-modal"
                className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
                role="dialog"
                aria-modal="true"
                show={searchOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
                <div className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg" ref={searchContent}>
                    {
                        page === 'search' ? <>

                            <form className="border-b border-gray-200" onSubmit={chill}>
                                <div className="relative">
                                    <label htmlFor="modal-search" className="sr-only">Search</label>

                                    <input id="modal-search"
                                        className="w-full border-0 focus:ring-transparent placeholder-gray-400 appearance-none py-3 pl-10 pr-4"
                                        type="search"
                                        placeholder="Search Anything…"
                                        autoComplete="off"
                                        onChange={searchChange}
                                        value={searchValue}
                                        ref={searchInput} />
                                    <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                                        <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400 group-hover:text-gray-500 ml-4 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                                            <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                                        </svg>
                                    </button>
                                </div>
                            </form>
                            <div className="py-4 px-2" onFocus={() => setSearchOpen(true)} onBlur={() => setSearchOpen(false)}>
                                {/* Recent searches */}
                                <div className="mb-3 last:mb-0">
                                    <div className="text-xs font-semibold text-gray-400 uppercase px-2 mb-2">Result</div>
                                    <ul className="text-sm">
                                        {
                                            searchValue ? <li>
                                                <Link
                                                    className="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group"
                                                    to="#0"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        newItem()
                                                    }}>
                                                    🆕
                                                    <span>{searchValue}</span>
                                                </Link>
                                            </li> : null
                                        }
                                        {foodItems
                                            .filter(item => {
                                                return item?.name.toLowerCase().includes(searchValue.toLowerCase())
                                            })
                                            .map(item => {

                                                return (
                                                    <li key={item.name}>
                                                        <Link
                                                            className="flex items-center p-2 text-gray-800 hover:text-white hover:bg-indigo-500 rounded group"
                                                            to="#0"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                selectItem(item)

                                                            }}
                                                        >
                                                            {item.icon}
                                                            <span>{item.name}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            </div>
                        </> : null
                    }

                    {page === 'qty' ?
                        <div className="flex-grow">
                            {/* Panel body */}
                            <div className="p-6 space-y-6">
                                {/* Picture */}
                                <section>
                                    <h2 className="text-xl text-gray-800 font-bold mb-5">{item?.icon ?? '🍴'} {item?.name}</h2>
                                </section>
                                {/* Business Profile */}
                                <section>
                                    <form>
                                        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 mt-5 md:flex-col md:items-start">
                                            <div className="">
                                                <label className="block text-sm font-medium mb-1" htmlFor="name">Sugar ({unitMap[item?.unit]})</label>
                                                <input
                                                    id="name"
                                                    value={sugar}
                                                    onChange={sugarChange}
                                                    className="form-input w-full"
                                                    type="number" />
                                            </div>
                                            <div className=" sm:m-0">
                                                <label className="block text-sm font-medium mb-1" htmlFor="business-id">Quantity</label>
                                                <div className="flex justify-between">
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setQty(parseInt(qty) - 1)
                                                        }}
                                                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                                        -
                                                </button>
                                                    <input
                                                        id="business-id"
                                                        value={qty}
                                                        onChange={qtyChange}
                                                        className="form-input"
                                                        type="text" />
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            setQty(parseInt(qty) + 1)
                                                        }}
                                                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                                        +
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </section>
                            </div>
                            {/* Panel footer */}
                            <footer>
                                <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                                    <div className="flex self-end">
                                        <button onClick={() => { onDiscard(); setSearchOpen(false) }} className="btn border-gray-200 bg-red-500 hover:bg-red-600 hover:border-gray-300 text-white">Discard</button>
                                        <button onClick={onAdd} className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Confirm</button>
                                    </div>
                                </div>
                            </footer>
                        </div>
                        : null}


                    {page === 'new' ?

                        <div className="flex-grow">
                            {/* Panel body */}
                            <div className="p-6 space-y-6">
                                {/* Picture */}
                                <section>
                                    <h2 className="text-xl text-gray-800 font-bold mb-5">
                                        <button onClick={() => setPicker(true)} className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                            {icon}
                                        </button>
                                        {
                                            picker ? <Picker
                                                onClick={chill}
                                                onSelect={selectEmoji}
                                                include='foods' style={{
                                                    position: 'absolute', marginLeft: '-56px',
                                                    marginTop: '45px'
                                                }} /> : null
                                        }
                                    </h2>
                                </section>
                                <section>
                                    <form>
                                        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                                            <div className="sm:w-1/3 xl:w-full">
                                                <label className="block text-sm font-medium mb-1">Name </label>
                                                <input
                                                    autoComplete="off"
                                                    id="name"
                                                    value={name}
                                                    onChange={nameChange}
                                                    className="form-input w-full"
                                                    type="text" />
                                            </div>

                                            <div>
                                                <ul className="mb-4" onChange={unitChange}>
                                                    <li className="py-1 px-3">
                                                        <label className="flex items-center">
                                                            <input type="radio" checked={unit === 'n'} value='n' name='unit' className="form-checkbox" />
                                                            <span className="text-sm font-medium ml-2">Unit</span>
                                                        </label>
                                                    </li>
                                                    <li className="py-1 px-3">
                                                        <label className="flex items-center">
                                                            <input type="radio" checked={unit === 'g'} value='g' name='unit' className="form-checkbox" />
                                                            <span className="text-sm font-medium ml-2">Weight (g)</span>
                                                        </label>
                                                    </li>
                                                    <li className="py-1 px-3">
                                                        <label className="flex items-center">
                                                            <input type="radio" checked={unit === 'ml'} value='ml' name='unit' className="form-checkbox" />
                                                            <span className="text-sm font-medium ml-2">Volume (ml)</span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>

                                            {
                                                unit ?

                                                    <>
                                                        <div className="sm:w-1/3">
                                                            <label className="block text-sm font-medium mb-1" htmlFor="suar">Sugar (g)</label>
                                                            <input
                                                                id="sugar"
                                                                value={sugar}
                                                                onChange={sugarChange}
                                                                autoComplete="off"
                                                                className="form-input w-full"
                                                                type="number" />
                                                        </div>
                                                        <div className="sm:w-1/3">
                                                            <label className="block text-sm font-medium mb-1" htmlFor="business-id">Per</label>
                                                            <div className="flex justify-between">

                                                                <input
                                                                    id="business-id"
                                                                    autoComplete="off"
                                                                    value={qty}
                                                                    onChange={qtyChange}
                                                                    className="form-input"
                                                                    type="text" />
                                                                {unit}
                                                            </div>
                                                        </div>

                                                    </>
                                                    : null

                                            }
                                        </div>
                                    </form>
                                </section>
                            </div>
                            <footer>
                                <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                                    <div className="flex self-end">
                                        <button onClick={() => setSearchOpen(false)} className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
                                        <button onClick={onNew} className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Add</button>
                                    </div>
                                </div>
                            </footer>
                        </div>
                        : null}
                </div>
            </Transition>
        </div>
    )
}

export default MealModal;