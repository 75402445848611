import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import Transition from '../../utils/Transition.js';
import { useInput } from '../../utils/UseInput';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import { FoodItemContext } from '../../context/db.js';

const chill = (e) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
}

function FoodModal({ baseItem, children, force }) {

    const [searchOpen, setSearchOpen] = useState(false);
    const [name, nameChange, setName] = useInput('')
    const [unit, unitChange, setUnit] = useInput('')
    const [sugar, sugarChange, setSugar] = useInput(0)
    const [qty, qtyChange, setQty] = useInput(1)
    const trigger = useRef(null);
    const searchContent = useRef(null);
    const nameInput = useRef(null);
    const [icon, setIcon] = useState('🍴')
    const [picker, setPicker] = useState(false)
    const { addFoodItem, updateFoodItem, deleteFoodItem } = useContext(FoodItemContext)



    const openModal = useCallback(() => {
        if (baseItem) {
            setSugar(baseItem.sugar * baseItem.base)
            setName(baseItem.name)
            setIcon(baseItem.icon)
            setUnit(baseItem.unit)
            setQty(baseItem.base)
        }

        setSearchOpen(!searchOpen);

    }, [setSearchOpen, searchOpen, baseItem, setQty, setSugar, setName, setUnit])

    useEffect(() => {
        if (force) {
            openModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [force])

    const onAdd = useCallback(() => {

        if (baseItem && !force) {
            updateFoodItem({
                ...baseItem,
                name,
                unit,
                icon,
                base: qty,
                sugar: sugar / qty
            })
        } else {
            addFoodItem({
                name,
                unit,
                icon,
                base: qty,
                sugar: sugar / qty
            })
        }
        setSearchOpen(false)

    }, [setSearchOpen, qty, name, unit, icon, sugar, addFoodItem, baseItem, updateFoodItem, force])


    const onDiscard = useCallback(() => {
        deleteFoodItem(baseItem.id);
        setSearchOpen(false)
    }, [baseItem, deleteFoodItem])

    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (picker) return
            if (!searchOpen || searchContent?.current?.contains?.(target) || trigger?.current?.contains?.(target)) return;
            setSearchOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!searchOpen || keyCode !== 27) return;
            setSearchOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const selectEmoji = useCallback((emoji) => {
        setIcon(emoji.native)
        setPicker(false)
    }, [])

    return (
        <div>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                ref={trigger}
                aria-controls="search-modal"

                onClick={() => {
                    openModal()
                    setImmediate(() => nameInput?.current?.focus());
                }}
            >
                {children}
            </button>
            {/* Modal backdrop */}
            <Transition
                className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
                show={searchOpen}
                enter="transition ease-out duration-200"
                enterStart="opacity-0"
                enterEnd="opacity-100"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
                aria-hidden="true"
            />
            {/* Modal dialog */}
            <Transition
                id="search-modal"
                className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center transform px-4 sm:px-6"
                role="dialog"
                aria-modal="true"
                show={searchOpen}
                enter="transition ease-in-out duration-200"
                enterStart="opacity-0 translate-y-4"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-in-out duration-200"
                leaveStart="opacity-100 translate-y-0"
                leaveEnd="opacity-0 translate-y-4"
            >
                <div className="bg-white overflow-auto max-w-2xl w-full max-h-full rounded shadow-lg" ref={searchContent}>
                    <div className="flex-grow">
                        {/* Panel body */}
                        <div className="p-6 space-y-6">
                            {/* Picture */}
                            <section>
                                <h2 className="text-xl text-gray-800 font-bold mb-5">
                                    <button onClick={() => setPicker(true)} className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                                        {icon}
                                    </button>
                                    {
                                        picker ? <Picker
                                            onClick={chill}
                                            onSelect={selectEmoji}
                                            include={['foods']} style={{
                                                position: 'absolute', marginLeft: '-56px',
                                                marginTop: '45px'
                                            }} /> : null
                                    }
                                </h2>
                            </section>
                            <section>
                                <form>
                                    <div className="sm:flex sm:items-center md:flex-col md:items-start sm:space-y-0 mt-5">
                                        <div className="sm:w-1/3 xl:w-full">
                                            <label className="block text-sm font-medium mb-1">Name </label>
                                            <input
                                                autoComplete="off"
                                                id="name"
                                                value={name}
                                                onChange={nameChange}
                                                className="form-input w-full"
                                                type="text" />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium mt-2">Unit </label>

                                            <ul className="mb-4" onChange={unitChange}>
                                                <li className="py-1 px-3">
                                                    <label className="flex items-center">
                                                        <input type="radio" defaultChecked={unit === 'n'} value='n' name='unit' className="form-checkbox" />
                                                        <span className="text-sm font-medium ml-2">Unit</span>
                                                    </label>
                                                </li>
                                                <li className="py-1 px-3">
                                                    <label className="flex items-center">
                                                        <input type="radio" defaultChecked={unit === 'g'} value='g' name='unit' className="form-checkbox" />
                                                        <span className="text-sm font-medium ml-2">Weight (g)</span>
                                                    </label>
                                                </li>
                                                <li className="py-1 px-3">
                                                    <label className="flex items-center">
                                                        <input type="radio" defaultChecked={unit === 'ml'} value='ml' name='unit' className="form-checkbox" />
                                                        <span className="text-sm font-medium ml-2">Volume (ml)</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>

                                        {
                                            unit ?

                                                <>
                                                    <div className="sm:w-1/3 m-0">
                                                        <label className="block text-sm font-medium mb-1" htmlFor="suar">Sugar (g)</label>
                                                        <input
                                                            id="sugar"
                                                            value={sugar}
                                                            onChange={sugarChange}
                                                            autoComplete="off"
                                                            className="form-input w-full"
                                                            type="number" />
                                                    </div>
                                                    <div className="sm:w-1/3">
                                                        <label className="block text-sm font-medium mb-1" htmlFor="business-id">Per</label>
                                                        <div className="flex justify-between">

                                                            <input
                                                                id="business-id"
                                                                autoComplete="off"
                                                                value={qty}
                                                                onChange={qtyChange}
                                                                className="form-input"
                                                                type="text" />
                                                            {unit}
                                                        </div>
                                                    </div>

                                                </>
                                                : null

                                        }
                                    </div>
                                </form>
                            </section>
                        </div>
                        <footer>
                            <div className="flex flex-col px-6 py-5 border-t border-gray-200">
                                <div className="flex self-end">
                                    <button onClick={onDiscard} className="btn bg-red-500 border-red-200 hover:border-red-300 text-white">Discard</button>
                                    <button onClick={onAdd} className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Confirm</button>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Transition>
        </div >
    )
}

export default FoodModal;