import React, { useEffect, useContext } from 'react';
import { focusHandling } from 'cruip-js-toolkit';
import FoodTableItem from './FoodTableItem';

import { FoodItemContext } from '../../context/db';

function FoodTable() {

    // const { foodItems } = useContext(FoodContext)
    const { foodItems } = useContext(FoodItemContext)

    useEffect(() => {
        focusHandling('outline');
    }, [foodItems]);

    return (
        <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
            <header className="px-5 py-4">
                <h2 className="font-semibold text-gray-800">
                    All Items <span className="text-gray-400 font-medium">{foodItems?.length}</span>
                </h2>
            </header>
            <div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="table-auto w-full">
                        {/* Table header */}
                        <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
                            <tr>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-left">Name</div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-left">Sugar</div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-semibold text-left">Icon</div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <span className="sr-only">Menu</span>
                                </th>
                            </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="text-sm divide-y divide-gray-200">
                            {
                                foodItems.map(food => {
                                    return (
                                        <FoodTableItem
                                            key={food.id}
                                            name={food.name}
                                            sugar={food.sugar}
                                            icon={food.icon}
                                            unit={food.unit}
                                            base={food.base}
                                            id={food.id}
                                        />
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    );
}

export default FoodTable;
