import { useEffect, useState, createContext, useCallback } from 'react';
import Signup from '../pages/Signup';

export const UserContext = createContext()

export const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(null)


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))

        if (user) {
            setUser(user)
        }
    }, [])

    const saveUser = useCallback((user) => {
        setUser(user)
        localStorage.setItem('user', JSON.stringify(user))
    }, [setUser])

    return (

        <UserContext.Provider value={{ user, saveUser }}>

            {user? children: <Signup/>}
            {/* {children} */}
        </UserContext.Provider>
    )
}