import firebase from 'firebase/app'
import 'firebase/firestore'
import { createContext, useCallback } from 'react'

import { useCollectionData } from 'react-firebase-hooks/firestore'

firebase.initializeApp({
    apiKey: "AIzaSyCIWUy8YtrfKEhXjtCUiWKUJJqztos9nFU",
    authDomain: "sugar-mama-e64ed.firebaseapp.com",
    projectId: "sugar-mama-e64ed",
    storageBucket: "sugar-mama-e64ed.appspot.com",
    messagingSenderId: "954870381208",
    appId: "1:954870381208:web:cb2673fc163246dfa72eed"
})

const firestore = firebase.firestore()

export const FoodItemContext = createContext()


export const FoodItemsContextProvider = ({ children }) => {

    const foodItemsRef = firestore.collection('foodItems')


    const [foodItems] = useCollectionData(foodItemsRef, {idField: 'id'})

    const addFoodItem = useCallback(async (item) => {
        return await foodItemsRef.add(item)
    }, [foodItemsRef])

    const updateFoodItem = useCallback(async (item) => {
        await foodItemsRef.doc(item.id).update(item)
    }, [foodItemsRef])

    const deleteFoodItem = useCallback(async (id) => {
        await foodItemsRef.doc(id).delete()
    }, [foodItemsRef])


    return (
        <FoodItemContext.Provider value={{ foodItems: foodItems ?? [], addFoodItem, updateFoodItem, deleteFoodItem }}>
            {children}
        </FoodItemContext.Provider>
    )
}