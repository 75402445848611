import { nanoid } from 'nanoid'

export const getProduct = async (id) => {


    try {
        const raw = await fetch(`https://world.openfoodfacts.org/api/v0/product/${id}.json`)
        const { product } = await raw.json()

        const {
            code,
            nutriments,
            nutriscore_data: {
                sugars
            },
            nutrition_data_per,
            product_name_fr,
            quantity,
            product_quantity } = product;

        const data = {
            code,
            nutriments,
            sugars,
            nutrition_data_per,
            product_name_fr,
            quantity,
            product_quantity
        }
        console.log(data)

        const [num] = quantity.match(/\d+/g);
        const base = parseInt(num)
        const [unit] = quantity.match(/[a-zA-Z]+/g);
        const [per] = nutrition_data_per.match(/\d+/g)
        const ratio = sugars / per;


        console.log('num', typeof num)
        console.log('unit', unit)
        console.log('sugars', sugars)
        console.log('nutrition_data_per', nutrition_data_per)
        console.log('ratio', ratio)
        console.log('g', ratio * base)
        const item = {
            name: product_name_fr,
            sugar: ratio,
            icon: '🍴',
            unit,
            base,
            id: nanoid()
        }
        console.log(item)

        return item
    }
    catch (e) {
        alert(e)
    }

}