import React, { useCallback, useContext, useEffect } from 'react';
import { UserContext } from '../../context/user';
import { useHistory } from 'react-router-dom';

// import Image from '../../images/user-avatar-80.png';
import { useInput } from '../../utils/UseInput';

function SettingsContent() {

  const [breakfast, breakfastChange, setBreakfast] = useInput()
  const [lunch, lunchChange, setLunch] = useInput()
  const [dinner, dinnerChange, setDinner] = useInput()
  const { user, saveUser } = useContext(UserContext)
  const history = useHistory();

  useEffect(() => {
    if (user) {
      setBreakfast(user.breakfast)
      setLunch(user.lunch)
      setDinner(user.dinner)
    }

  }, [user, setBreakfast, setLunch, setDinner])

  const onSubmit = useCallback((e) => {

    e.preventDefault()
    saveUser(
      {
        ...user,
        breakfast,
        lunch,
        dinner
      }
    )
    history.push("/");

  }, [breakfast, lunch, dinner, user, saveUser, history])

  const onCancel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    setBreakfast(user.breakfast)
    setDinner(user.dinner)
    setLunch(user.lunch)
    history.push("/");

  }, [user, setBreakfast, setLunch, setDinner, history])


  return (
    <div className="flex-grow">
      <form onSubmit={onSubmit}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          {/* Picture */}
          {/* <section>
            <h2 className="text-xl text-gray-800 font-bold mb-5">Picture</h2>
            <div className="flex items-center">
              <div className="mr-4">
                <img className="w-20 h-20 rounded-full" src={Image} width="80" height="80" alt="User upload" />
              </div>
              <button className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white">Change</button>
            </div>
          </section> */}
          {/* Business Profile */}
          <section>
            <h2 className="text-xl text-gray-800 font-bold mb-1">Sugar Profile</h2>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="breakfast">Breakfast</label>
                <input autoComplete="off" step="0.1" value={breakfast} onChange={breakfastChange} id="name" className="form-input w-full" type="number" />
              </div>
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="lunch">Lunch</label>
                <input autoComplete="off" step="0.1" value={lunch} onChange={lunchChange} id="business-id" className="form-input w-full" type="number" />
              </div>
              <div className="sm:w-1/3">
                <label className="block text-sm font-medium mb-1" htmlFor="dinner">Dinner</label>
                <input autoComplete="off" step="0.1" value={dinner} onChange={dinnerChange} id="location" className="form-input w-full" type="number" />
              </div>
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-gray-200">
            <div className="flex self-end">
              <button onClick={onCancel} className="btn border-gray-200 hover:border-gray-300 text-gray-600">Cancel</button>
              <button type='submit' className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3">Save Changes</button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  );
}

export default SettingsContent;