
import { useState, useCallback } from 'react'

export const Input = (props) => <input
    {...props} />

export const useInput = (initialValue) => {

    const [value, setValue] = useState(initialValue)

    const onChange = useCallback((event) => setValue(event.target.value), [setValue])
    return [
        value,
        onChange,
        setValue
    ]
}

export const chill = (e) => {
    e?.preventDefault?.()
    e?.stopPropagation?.()
}

