import React from 'react';
import FoodModal from './FoodModal';

function CustomersTableItem(props) {
  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">{props.name}</div>
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{`${(props.sugar * props.base).toFixed(1)}g per ${props.base}${props.unit}`} </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left font-medium text-light-blue-500">{props.icon}</div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
        <FoodModal baseItem={props}>
          <svg className="w-4 h-4 flex-shrink-0 fill-current text-gray-400">
            <path d="M1 16h3c.3 0 .5-.1.7-.3l11-11c.4-.4.4-1 0-1.4l-3-3c-.4-.4-1-.4-1.4 0l-11 11c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1zm1-3.6l10-10L13.6 4l-10 10H2v-1.6z">
            </path>
          </svg>
        </FoodModal>
      </td>
    </tr>
  );
}

export default CustomersTableItem;
